import Vue from 'vue'
import Router from 'vue-router'
// import Home from '../components/Home.vue'
// import Login from '../components/Login.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/home/one',
            hidden: true,
            component: () => import('@/components/HomeOne'),
        },
        {
            path: '/login',
            name: '登录页面',
            hidden: true,
            component: () => import('@/components/Login'),
        },
        {
            path: '/register',
            name: '注册页面',
            hidden: true,
            component: () => import('@/components/Register'),
        },
        {
            path: '/reset',
            name: '重置密码',
            hidden: true,
            component: () => import('@/components/ResetPassword'),
        },
        {
            path: '/tick',
            name: '提示页面',
            hidden: true,
            component: () => import('@/components/Tick'),
        },
        // {
        //     path: '*',
        //     name: '404页面',
        //     hidden: true,
        //     component: () => import('@/components/NotFound'),
        // },
        {
            path: '/home',
            name: '首页信息',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-s-home bh',
            children: [
                {
                    path: '/home/one',
                    name: '首页',
                    iconClass: 'el-icon-s-home',
                    component: () => import('@/components/HomeOne'),
                },
            ]
        },
        {
            path: '/tunnel',
            name: '隧道管理',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-s-unfold bh',
            children: [
                {
                    path: '/tunnelm/manage',
                    name: '隧道列表',
                    iconClass: 'el-icon-s-operation',
                    component: () => import('@/components/tunnelm/Manage'),
                },
                {
                    path: '/tunnelm/dw',
                    name: '软件下载',
                    iconClass: 'el-icon-download',
                    component: () => import('@/components/tunnelm/Dw'),
                },
                {
                    path: '/tunnelm/config',
                    name: '配置文件',
                    iconClass: 'el-icon-document',
                    component: () => import('@/components/tunnelm/Config')
                }
            ]
        },
        {
            path: '/user',
            name: '个人中心',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-user-solid bh',
            children: [
                {
                    path: '/user/info',
                    name: '用户信息',
                    iconClass: 'el-icon-user',
                    component: () => import('@/components/user/Info'),
                },
                {
                    path: '/user/realname',
                    name: '实名认证',
                    rename: true,
                    iconClass: 'el-icon-bank-card',
                    component: () => import('@/components/user/Realname'),
                }
            ]
        },
        {
            path: '/extend',
            name: '扩展中心',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-menu bh',
            children: [
                {
                    path: '/extend/store',
                    name: '积分充值',
                    iconClass: 'el-icon-sell',
                    component: () => import('@/components/extend/Store'),
                },
                {
                    path: '/extend/integral',
                    name: '积分商城',
                    iconClass: 'el-icon-sold-out',
                    component: () => import('@/components/extend/Integral'),
                }
            ]
        },
        {
            path: '/other',
            name: '其他功能',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-info bh',
            children: [
                {
                    path: '/other/About',
                    name: '关于面板',
                    iconClass: 'el-icon-warning-outline',
                    component: () => import('@/components/other/About'),
                }
            ]
        },
        {
            path: '/admin',
            name: '管理面板',
            component: () => import('@/components/Home'),
            iconClass: 'el-icon-s-check bh',
            admin: true,
            children: [
                {
                    path: '/admin/home',
                    name: '首页',
                    iconClass: 'el-icon-s-home',
                    component: () => import('@/components/admin/Home'),
                },
                {
                    path: '/admin/Page',
                    name: '面板管理',
                    iconClass: 'el-icon-s-platform',
                    component: () => import('@/components/admin/Page'),
                },
                {
                    path: '/admin/user',
                    name: '用户管理',
                    iconClass: 'el-icon-s-custom',
                    component: () => import('@/components/admin/User'),
                },
                {
                    path: '/admin/tunnel',
                    name: '隧道管理',
                    iconClass: 'el-icon-s-unfold',
                    component: () => import('@/components/admin/Tunnel'),
                },
                {
                    path: '/admin/node',
                    name: '节点管理',
                    iconClass: 'el-icon-s-marketing',
                    component: () => import('@/components/admin/Node'),
                },
                {
                    path: '/admin/gift',
                    name: '兑换码管理',
                    iconClass: 'el-icon-s-ticket',
                    component: () => import('@/components/admin/Gift'),
                },
            ]
        },
    ],
    mode: 'history'
})